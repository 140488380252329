import {
  CorporateFundResponse,
  TransactionResponseStatus,
} from '@src/service/apis/generatedv3/corporate-fund-controller';
import { Dayjs } from 'dayjs';

export interface DetailsItem {
  header: string;
  value: string | number | JSX.Element;
}

export interface FundingWindowDetails {
  data: CorporateFundResponse;
}

export interface ModalCtrlProps {
  modalFlag: boolean;
  modalType?: 'accept' | 'reject' | 'update';
  selectedItemId?: number;
}

export enum ModalTypes {
  ACCEPT = 'accept',
  REJECT = 'reject',
  UPDATE = 'update',
}

export interface StatusCellProps {
  status: keyof typeof TransactionResponseStatus;
  id?: string | number;
}

export enum Locales {
  EN = 'en',
  ID = 'id-ID',
}

export interface IFundDates {
  open: Dayjs;
  close: Dayjs;
}
